import { User } from '../types'
import api from './api.service'

class UserService {
  static create(body: User) {
    return api.post<User>('/user', body)
  }

  static async getById(id: User['uuid']) {
    return (await api.get<User>(`/admin/users/${id}`)).data
  }

  static async list(params: { limit: number; offset: number; search: string }) {
    return (await api.get<{ total: number; users: User[] }>(`/admin/users`, { params })).data
  }

  static update(body: User) {
    return api.put<User>('/xzpto', body)
  }

  static updatePassword(body: { password: string }, token?: string) {
    return api.put<User>('/user/password', body, { headers: { Authorization: `Bearer ${token}` } })
  }
}

export default UserService
