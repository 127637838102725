import { Icon } from '../base/icon'
import { DOTS, usePagination } from './hooks'

export type HookProps = {
  totalCount: number
  currentPage: number
}

export type ArrowProps = {
  handleClick: () => void
  canClick?: boolean
  right?: boolean
  label: string
}

export interface Props extends HookProps {
  onPageChange: (page: number) => void
  ArrowComponent?: React.FC<ArrowProps>
  className?: string
  ariaLabel?: string
}

const Arrow: React.FC<ArrowProps> = ({ handleClick, canClick, right, label }) => {
  return (
    <button
      {...(canClick && { onClick: handleClick })}
      disabled={!canClick}
      id={`${label}-btn`}
      className='p-2'
    >
      <Icon
        name={right ? 'angle-right-alt' : 'angle-left-alt'}
        color={!canClick ? '#B2B2B2' : '#000000'}
        size='18'
      />
    </button>
  )
}

const validateCurrentPage = (page: number, totalCount: number) =>
  page <= totalCount && page >= 1 ? page : 1

export const Pagination: React.FC<Props> = props => {
  const { onPageChange, totalCount, ArrowComponent, className } = props
  const currentPage = validateCurrentPage(props.currentPage, props.totalCount)
  const paginationRange = usePagination({
    totalCount: totalCount <= 0 ? 1 : totalCount,
    currentPage
  })

  const canNext = currentPage < totalCount
  const canBack = currentPage > 1

  const onNext = () => onPageChange(currentPage + 1)
  const onPrevious = () => onPageChange(currentPage - 1)

  return (
    <nav
      aria-label={props.ariaLabel}
      className={`flex flex-row ${className} items-center justify-between`}
    >
      {ArrowComponent ? (
        <ArrowComponent handleClick={onPrevious} canClick={canBack} label='anterior' />
      ) : (
        <Arrow handleClick={onPrevious} canClick={canBack} label='anterior' />
      )}

      <ul className='flex flex-row items-center gap-2'>
        {paginationRange.map((label, index) => (
          <li key={`${label}-${index}`}>
            {label === DOTS ? (
              <div className='flex h-9 w-9 items-center justify-center text-black'>{label}</div>
            ) : (
              <button
                color={currentPage === parseInt(`${label}`) ? 'primary' : 'transparent'}
                onClick={() => onPageChange(parseInt(`${label}`))}
                className={`flex h-9 w-9 items-center justify-center rounded-full 
                ${currentPage !== parseInt(`${label}`) ? 'text-black hover:bg-transparent' : 'bg-background-brand-primary text-white'}
                ${label === DOTS && 'cursor-default '}
              `}
              >
                {label}
              </button>
            )}
          </li>
        ))}
      </ul>

      {ArrowComponent ? (
        <ArrowComponent handleClick={onNext} canClick={canNext} label='Próximo' />
      ) : (
        <Arrow handleClick={onNext} canClick={canNext} right label='Próximo' />
      )}
    </nav>
  )
}
