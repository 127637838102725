import React from 'react'
import { Props } from './types'
import { formatDate } from 'date-fns'
import { Icon } from '../../../../../components/base/icon'
import theme from '../../../../../styles/figma-tokens.json'

const MemberNavigation: React.FC<Props> = ({ user, tabs, current, handleChange }) => {
  return (
    <div className='mx-8'>
      <div className='sticky top-0 w-72 rounded-lg shadow-md'>
        <img
          className='h-20 w-full rounded-t-lg'
          src='/images/members/member-background.png'
          alt='Image purple of flat logo'
        />

        <div className='flex w-full flex-col px-3'>
          <div className='relative flex flex-col px-3'>
            <div className='absolute top-[-32px] flex h-16 w-16 items-center justify-center rounded-xs bg-background-brand-secondary text-background-on-brand-secondary'>
              <span className='text-lg font-medium'>{user?.name.charAt(0).toUpperCase()}</span>
            </div>

            <span className='mt-10 text-md font-medium text-text-primary'>{user.name}</span>
            <span className='font-brand text-2xs text-text-tertiary'>
              Member since {formatDate(new Date(user.createdAt), 'yyyy')}
            </span>
          </div>

          <div className='mt-8 flex flex-col pb-3'>
            {tabs.map(tab => {
              const isChecked = current.key === tab.key

              return (
                <button
                  key={tab.key}
                  onClick={() => handleChange(tab)}
                  className={`${isChecked ? 'bg-background-brand-secondary text-text-brand' : 'text-text-tertiary'} flex w-full cursor-pointer items-center rounded-sm p-3`}
                >
                  {tab.icon && (
                    <Icon
                      size='18px'
                      name={tab.icon}
                      color={
                        isChecked
                          ? theme.global.colorReference.brand[48].value
                          : theme.global.colorReference.neutral[40].value
                      }
                    />
                  )}

                  <span className={'ml-3 text-2xs font-regular'}>{tab.label}</span>
                </button>
              )
            })}
          </div>

          <div />
        </div>
      </div>
    </div>
  )
}

export default MemberNavigation
