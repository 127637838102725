import React from 'react'
import { FieldProps } from '../../types'

const CustomRadio: React.FC<FieldProps> = ({ options, value, onChange }) => {
  return (
    <li className='flex w-full flex-col'>
      {options?.map((option, index) => (
        <ul key={option.value.toString()} className='flex w-full'>
          <button
            onClick={() => onChange(option.value)}
            id={`${option.label.toLowerCase()}-button`}
            className={`flex w-full items-center justify-between py-4 ${options.length - 1 !== index && 'border-b-[1px] border-neutral-80'}`}
          >
            <div className='flex flex-col items-start'>
              <span className='text-xs font-regular text-text-primary'>{option.label}</span>
              {option.description && (
                <span className='text-2xs font-regular text-text-secondary'>
                  {option.description}
                </span>
              )}
            </div>

            <div
              className={`flex h-6 w-6 items-center justify-center rounded-full border-[1px] border-background-brand-primary ${value === option.value && 'bg-background-brand-primary'}`}
            >
              <div className='h-3 w-3 rounded-full bg-white' />
            </div>
          </button>
        </ul>
      ))}
    </li>
  )
}

export default CustomRadio
