import * as Yup from 'yup'
import { useFormik } from 'formik'
import Button from '../../components/base/button'
import { ResetPasswordFormValues } from './types'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes/path'
import LogoutHeader from '../../components/logout-header'
import Counter from '../../components/counter'
import AuthService from '../../services/auth.service'
import DefaultLoader from '../../components/base/loaders/default'
import { Input } from '../../components/base/input'
import { Icon } from '../../components/base/icon'

const validationSchema = Yup.object({
  password: Yup.string()
    .required('Password is required')
    .min(7, 'Password must be at least 7 characters long.')
    .matches(/\d+/, 'Password must contain at least one number.')
    .matches(/[A-Za-z]/, 'Password must contain at least one letter.'),
  rePassword: Yup.string()
    .required('Confirmation is required')
    .oneOf([Yup.ref('password')], 'Passwords must match.')
})

type Props = {
  handleBack: () => void
  initialValues: ResetPasswordFormValues
}

const ResetPassword: React.FC<Props> = ({ handleBack, initialValues }) => {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: ResetPasswordFormValues) => {
      try {
        if (values.password) {
          await AuthService.recoveryPassword({
            password: values.password,
            recoveryToken: values.code,
            username: values.username
          })
          navigate(ROUTES.login)
        }
      } catch (_) {}
    }
  })

  const handleExpire = () => {
    try {
      handleBack()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className='mb-6 flex flex-col items-center'>
      <LogoutHeader />

      <div className='mt-4 flex w-[454px] flex-col'>
        <Counter length={600} handleExpire={handleExpire} />
        <button className='flex items-center' onClick={handleBack}>
          <Icon name='arrow-left' size='18px' />
          <span className='font-semibold text-neutral-8'>Back</span>
        </button>

        <div className='flex flex-col'>
          <span className='mt-3 text-xl font-medium text-neutral-8'>Enter your new password</span>
          <span className='mt-2 text-xs font-regular text-neutral-32'>
            Your password must contain at least 7 characters and include at least one letter and one
            number.
          </span>
        </div>

        {formik.isSubmitting ? (
          <DefaultLoader className='mt-8 w-full self-center' />
        ) : (
          <>
            <form onSubmit={formik.handleSubmit} className='mt-8 flex w-full flex-col gap-4'>
              <Input
                className='text-black'
                placeholder='New password'
                id='password'
                name='password'
                type='password'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                maxLength={100}
                errorMessage={
                  formik.touched.password && formik.errors.password
                    ? formik.errors.password
                    : undefined
                }
              />

              <Input
                className='text-black'
                placeholder='Confirm new password'
                id='rePassword'
                name='rePassword'
                type='password'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.rePassword}
                maxLength={100}
                errorMessage={
                  formik.touched.rePassword && formik.errors.rePassword
                    ? formik.errors.rePassword
                    : undefined
                }
              />

              <Button id='login' variant='primary' className='mt-4 rounded-sm py-4' type='submit'>
                Reset password
              </Button>
            </form>
            <button
              id='forgot-password-button'
              className='mt-11 w-fit text-2xs font-normal text-neutral-40 decoration-solid'
            >
              Need help? Please contact the support team.
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export default ResetPassword
