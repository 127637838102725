/* import LoginForm from './login-form' */
import { useState } from 'react'
import TwoFactoryForm from './two-factory-form'
import { LoginFormValues } from './types'
import LoginForm from './login-form'

const LoginPage = () => {
  const [loginData, setLoginData] = useState<LoginFormValues>()

  return (
    <div className='flex h-screen w-screen justify-center bg-neutral-100'>
      {!loginData ? (
        <LoginForm handleSubmit={setLoginData} />
      ) : (
        <TwoFactoryForm initialValues={loginData} handleBack={() => setLoginData(undefined)} />
      )}
    </div>
  )
}

export default LoginPage
