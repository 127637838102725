export const ROUTES = {
  home: {
    dashboards: {
      base: '/dashboards',
      detail: '/dashboards/:id'
    },
    member: {
      base: '/members',
      detail: '/members/:id'
    }
  },
  login: '/login',
  signUp: '/signup',
  forgotPassword: '/forgot-password'
}
