import { useAuth } from '../../contexts/auth'
import React from 'react'
import { Navigate, RouteProps, Outlet } from 'react-router-dom'
import Navbar from './navbar'
import { ProtectedProvider } from '../../contexts/ProtectedContext'
import Menu from './menu'

const ProtectedRoute: React.FC<RouteProps> = () => {
  const { user } = useAuth()

  if (!user) {
    return <Navigate to='/login' replace />
  }

  return (
    <ProtectedProvider>
      <Menu />
      <div className='flex' style={{ height: 'calc(100vh - 65px)' }}>
        <Navbar />
        <main className='h-full w-full overflow-auto bg-neutral-100 p-8'>
          <Outlet />
        </main>
      </div>
    </ProtectedProvider>
  )
}

export default ProtectedRoute
