import { Dashboard } from '../types'
import api from './api.service'

class DashboardService {
  static async getGuestToken(dash_id: string) {
    return (await api.get<string>(`/admin/superset/dashboards/${dash_id}/token`)).data
  }

  static async list(params?: { search?: string }) {
    return (await api.get<Dashboard[]>(`/admin/superset/dashboards`, { params })).data
  }

  static async likeDashboard(id: Dashboard['id']) {
    return (await api.post<void>(`/admin/superset/dashboards/${id}/like`)).data
  }

  static async dislikeDashboard(id: Dashboard['id']) {
    return (await api.post<void>(`/admin/superset/dashboards/${id}/dislike`)).data
  }
}

export default DashboardService
