import React, { useState } from 'react'
import { Props } from './types'
import Button from '../base/button'
import { User } from '../../types'
import FieldUpdater from './field-updater'
import { object, string } from 'yup'
import { FieldStep } from '../../pages/home/members/detail/tabs/member-info/tabs/types'

const AuthStep: FieldStep = {
  title: 'Confirm your password',
  callback: async user => console.log('update infos: ', user),
  validationSchema: object({
    password: string().required('Password is required')
  }),
  option_data: [
    {
      key: 'password' as keyof User,
      label: 'Password',
      type: 'input'
    }
  ]
}

const FieldViewer: React.FC<Props<User>> = ({
  data: { key, label, placeholder, update_steps, value_formatter, require_auth },
  entity,
  handleUpdate,
  isLast
}) => {
  const [showUpdate, setShowUpdate] = useState<boolean>(false)

  return (
    <>
      <div
        key={key}
        className={`flex w-full ${!isLast && 'border-b-[1px] border-outline-default'} items-center justify-between py-4`}
      >
        <div className='flex flex-col'>
          <span className='text-xs font-regular text-text-primary'>{label}</span>
          <span className='mt-1 text-2xs font-regular text-text-secondary'>
            {
              (value_formatter
                ? value_formatter(entity[key])
                : entity[key] || placeholder || 'Not defined') as string
            }
          </span>
        </div>

        {update_steps.length >= 1 && (
          <Button
            variant='secondary'
            id={label?.toString().toLowerCase()}
            className='rounded-sm text-3xs font-medium text-text-primary'
            onClick={() => setShowUpdate(true)}
          >
            Edit
          </Button>
        )}
      </div>

      {showUpdate && (
        <FieldUpdater
          handleClose={() => setShowUpdate(false)}
          handleUpdate={async e => await handleUpdate(e as typeof entity)}
          steps={[...update_steps, ...(require_auth ? [AuthStep] : [])]}
          isOpen={showUpdate}
          entity={entity}
        />
      )}
    </>
  )
}

export default FieldViewer
