import React, { useEffect, useState } from 'react'
import { Props } from './types'
import CircleLoader from '../base/loaders/circle'

const Counter: React.FC<Props> = ({ length, handleExpire }) => {
  const [seconds, setSeconds] = useState(length)

  useEffect(() => {
    setSeconds(length)
  }, [length])

  const date = new Date(0)
  date.setSeconds(seconds)
  const timeString = date.toISOString().substring(11, 19)

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => {
        if (seconds - 1 === 0) handleExpire()

        setSeconds(seconds - 1)
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds])

  return (
    <div className='flex w-full items-center justify-center'>
      <CircleLoader percentage={(seconds / length) * 100} colour='#523D8F' />
      <span className='text-2xs font-regular text-neutral-8'>
        {seconds === 0 ? '00:00' : timeString.substring(3, 99)}
      </span>
    </div>
  )
}

export default Counter
