import { DetailField } from './types'
import * as pages from './'
import { Club, User } from '../../../../../../../types'
import { formatDate } from 'date-fns'
import { object, string } from 'yup'

type Pages = keyof typeof pages

type UserUpdatable = {
  [page in Pages]: DetailField[]
}

const UserFields: UserUpdatable = {
  about: [
    {
      key: 'name',
      label: 'Name',
      require_auth: true,
      update_steps: [
        {
          title: 'Name',
          validationSchema: object({
            name: string().required('Name is required.'),
            lastName: string().required('Last name is required.')
          }),
          option_data: [
            {
              type: 'input',
              label: 'First name',
              key: 'name'
            },
            {
              type: 'input',
              label: 'Middle name',
              description: '*Optional',
              key: 'middleName'
            },
            {
              type: 'input',
              label: 'Last name',
              key: 'lastName'
            }
          ]
        }
      ]
    },
    {
      label: 'Preferred language',
      key: 'language',
      require_auth: true,
      update_steps: [
        {
          title: 'Preferred language',
          option_data: [
            {
              type: 'radio',
              label: 'Language',
              options: [
                {
                  label: 'English',
                  value: 'en-US'
                },
                {
                  label: 'Portuguese',
                  value: 'pt-BR'
                },
                {
                  label: 'Spanish',
                  value: 'spanish'
                }
              ],
              key: 'language'
            }
          ]
        }
      ]
    },
    {
      key: 'gender',
      label: 'Gender',
      require_auth: true,
      update_steps: [
        {
          title: 'Gender',
          option_data: [
            {
              type: 'radio',
              label: 'Gender',
              options: [
                {
                  label: 'Female',
                  value: 'female'
                },
                {
                  label: 'Male',
                  value: 'male'
                },
                {
                  label: 'Nonbinary',
                  value: 'non-binary',
                  description: 'Does not identify as male or female'
                },
                {
                  label: 'Prefer not to answer',
                  value: 'non-answer'
                },
                {
                  label: 'Other',
                  value: 'other'
                }
              ],
              key: 'gender'
            }
          ]
        }
      ]
    },
    {
      label: 'Ethnicity',
      key: 'ethnicity',
      require_auth: true,
      value_formatter: e => (e as string[])?.join().toString(),
      update_steps: [
        {
          title: 'Ethnicity',
          option_data: [
            {
              type: 'checkbox',
              label: 'Ethnicity',
              options: [
                {
                  label: 'Latino/Hispanic',
                  value: 'latino-hispanic'
                },
                {
                  label: 'Black/African American',
                  value: 'black-african'
                },
                {
                  label: 'White',
                  value: 'white'
                },
                {
                  label: 'Asian',
                  value: 'asian'
                },
                {
                  label: 'Native-American/American-Indian',
                  value: 'native-american'
                },
                {
                  label: 'Prefer not to answer',
                  value: 'non-answer'
                },
                {
                  label: 'Other',
                  value: 'other'
                }
              ],
              key: 'ethnicity'
            }
          ]
        }
      ]
    },
    {
      label: 'Financial goal',
      key: 'main_financial_goal',
      require_auth: true,
      update_steps: [
        {
          title: 'Financial goal',
          option_data: [
            {
              type: 'box',
              label: 'Financial goal',
              options: [
                {
                  label: `My children's education`,
                  value: 'education',
                  icon: 'graduation-cap'
                },
                {
                  label: 'Going on a family trip',
                  value: 'trip',
                  icon: 'plane-departure'
                },
                {
                  label: 'Buying a new car',
                  value: 'car',
                  icon: 'car'
                },
                {
                  label: 'Buying a new home',
                  value: 'home',
                  icon: 'home-alt'
                },
                {
                  label: 'Save for unexpected expense',
                  value: 'save',
                  icon: 'home-alt'
                },
                {
                  label: 'Other',
                  value: 'other',
                  icon: 'plus'
                }
              ],
              key: 'ethnicity'
            }
          ]
        }
      ]
    }
  ],
  account: [
    {
      key: 'email',
      label: 'Email',
      require_auth: true,
      update_steps: [
        {
          title: 'Email',
          validationSchema: object({
            email: string().required('Email is required.')
          }),
          option_data: [
            {
              type: 'input',
              label: 'Email',
              key: 'email'
            }
          ]
        }
      ]
    },
    {
      key: 'phone',
      label: 'Phone number',
      require_auth: true,
      update_steps: [
        {
          title: 'Phone number',
          validationSchema: object({
            phone: string().required('Phone is required.')
          }),
          option_data: [
            {
              type: 'input',
              label: 'Phone number',
              key: 'phone'
            }
          ]
        }
      ]
    },
    {
      key: 'secondPhone',
      label: 'Backup phone',
      require_auth: true,
      update_steps: [
        {
          title: 'Backup number',
          validationSchema: object({
            phone: string().required('Backup phone is required.')
          }),
          option_data: [
            {
              type: 'input',
              label: 'Backup number',
              key: 'secondPhone'
            }
          ]
        }
      ]
    },
    {
      key: 'ambassador',
      label: 'Ambassador',
      value_formatter: e => (e as User)?.name,
      require_auth: true,
      update_steps: [
        {
          validationSchema: object({
            ambassador: string().required('Ambassador is required.')
          }),
          title: 'Ambassador',
          option_data: [
            {
              type: 'radio',
              label: 'Ambassador',
              options: [
                {
                  label: 'guilherme_fernandes',
                  value: 'Guilherme Fernandes',
                  description: 'Ambassador since 2018'
                }
              ],
              key: 'ambassador'
            }
          ]
        }
      ]
    },
    {
      key: 'club' as keyof User,
      label: 'Club',
      value_formatter: e => (e as Club)?.name,
      require_auth: true,
      update_steps: [
        {
          validationSchema: object({
            ambassador: string().required('Club is required.')
          }),
          title: 'Club',
          option_data: [
            {
              type: 'radio',
              label: 'Club',
              options: [
                {
                  label: '[clube_name]',
                  value: 'club_name'
                }
              ],
              key: 'club'
            }
          ]
        }
      ]
    },
    {
      key: 'createdAt',
      label: 'Registration date',
      value_formatter: e => formatDate(new Date(e as string), 'dd MMMM yyyy, HH:mm').toString(),
      update_steps: []
    }
  ],
  address: [
    {
      label: 'Zip code',
      key: 'zipCode',
      require_auth: true,
      update_steps: [
        {
          title: 'Zip code',
          validationSchema: object({
            zipCode: string().required('Zip code is required.')
          }),
          option_data: [
            {
              type: 'input',
              label: 'Zip code',
              key: 'zipCode'
            }
          ]
        }
      ]
    }
  ]
}

export default UserFields
