const cleanPercentage = (percentage: number) => {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0 // we can set non-numbers to 0 here
  const isTooHigh = percentage > 100
  return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage
}

const Circle = ({ colour, percentage }: { colour: string; percentage?: number }) => {
  const r = 10
  const circ = 2 * Math.PI * r
  const strokePct = ((100 - (percentage || 0)) * circ) / 100 // where stroke will start, e.g. from 15% to 100%.
  return (
    <circle
      r={r}
      cx={175}
      cy={25}
      fill='transparent'
      stroke={strokePct !== circ ? colour : ''} // remove colour as 0% sets full circumference
      strokeWidth={'0.2rem'}
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePct : 0}
    />
  )
}

const CircleLoader = ({ percentage, colour }: { percentage: number; colour: string }) => {
  const pct = cleanPercentage(percentage)
  return (
    <svg width={50} height={50}>
      <g transform={`rotate(-90 ${'100 100'})`}>
        <Circle colour='lightgrey' />
        <Circle colour={colour} percentage={pct} />
      </g>
    </svg>
  )
}

export default CircleLoader
