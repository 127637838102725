import React from 'react'
import { Props } from '../types'
import { motion } from 'framer-motion'
import FieldViewer from '../../../../../../../../components/field-viewer'

const UserAbout: React.FC<Props> = ({ user, fields, handleUpdate }) => {
  return (
    <motion.div
      className='flex h-full w-full flex-col'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className='mb-8 flex h-[120px] w-[120px] items-center justify-center rounded-full bg-background-brown-secondary'>
        <span className='text-lg font-medium text-text-primary'>
          {user.fullname.substring(0, 2).toUpperCase()}
        </span>
      </div>

      {fields.map((field, index) => (
        <FieldViewer
          key={field.key}
          data={field}
          entity={user}
          handleUpdate={handleUpdate}
          isLast={fields.length - 1 === index}
        />
      ))}
    </motion.div>
  )
}

export default UserAbout
