import { useMemo } from 'react'
import { HookProps } from '..'

export const DOTS = '...'

const range = (start: number, end: number) => {
  const length = end - start + 1
  return Array.from({ length }, (_, idx) => idx + start)
}

export const usePagination = ({ totalCount, currentPage }: HookProps) => {
  const paginationRange = useMemo(() => {
    if (totalCount <= 3) {
      return range(1, totalCount)
    }

    const leftSiblingIndex = Math.max(currentPage - 1, 2)
    const rightSiblingIndex = Math.min(currentPage + 1, totalCount - 1)
    const middleRange = range(leftSiblingIndex, rightSiblingIndex)

    return [
      ...(currentPage > 3 ? [1, DOTS] : [1]),
      ...middleRange,
      ...(rightSiblingIndex < totalCount - 1 ? [DOTS] : []),
      totalCount
    ]
  }, [totalCount, currentPage])

  return paginationRange
}
