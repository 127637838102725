import ProtectedRoute from '../components/protected-route'
import ForgotPasswordPage from '../pages/forgotPassword'
import { createBrowserRouter } from 'react-router-dom'
import LoginPage from '../pages/login'
//import HomePage from '../pages/home'
import { ROUTES } from './path'
import UnProtectedRoute from '../components/unprotected-route'
import UserDetail from '../pages/home/members/detail'
import HomeMembers from '../pages/home/members'
import HomeDashboards from '../pages/home/dashboards'
import DashboardDetail from '../pages/home/dashboards/detail'

export const router = createBrowserRouter([
  {
    element: <UnProtectedRoute />,
    children: [
      {
        path: ROUTES.login,
        element: <LoginPage />
      },
      {
        path: ROUTES.forgotPassword,
        element: <ForgotPasswordPage />
      }
    ]
  },
  {
    element: <ProtectedRoute />,
    children: [
      { index: true, element: <HomeDashboards /> },
      { element: <DashboardDetail />, path: ROUTES.home.dashboards.detail },
      { element: <HomeDashboards />, path: ROUTES.home.dashboards.base },
      { element: <HomeMembers />, path: ROUTES.home.member.base },
      { element: <UserDetail />, path: ROUTES.home.member.detail }
    ]
  }
])
