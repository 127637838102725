import { UserStatusParser } from '../pages/home/members/table'

export enum UserOnboardingParser {
  'not-contacted' = 'Not contacted',
  'contacted' = 'Contacted'
}

export interface Club {
  id: number
  name: string
}

export interface User {
  profilePicture: string
  fullname: string
  countryCode: string
  id: number
  uuid: string
  gender: string
  username: string
  name: string
  zipCode: string
  middleName: string | null
  lastName: string | null
  email: string
  role: string
  privilege_level: number
  onboarding: keyof typeof UserOnboardingParser
  createdAt: string
  language: string
  status: keyof typeof UserStatusParser
  phone: string
  ethnicity: string[]
  main_financial_goal: string
  secondPhone: string
  ambassador: User
  club: Club
}

export interface AdminUser {
  admin: User
  user: User
}

export interface Dashboard {
  id: number
  name: string
  image: string
  isLiked: boolean
  supersetId: string
  description: string
}
