import React, { createContext, useContext, ReactNode, useState } from 'react'

interface ProtectedContextType {
  handleToggleNav: () => void
  navIsHidded: boolean
}

const ProtectedContext = createContext<ProtectedContextType | undefined>(undefined)

interface ProtectedProviderProps {
  children: ReactNode
}

export const ProtectedProvider: React.FC<ProtectedProviderProps> = ({ children }) => {
  const [isHidded, setIsHidded] = useState(false)

  const handleToggleNav = () => setIsHidded(previous => !previous)

  return (
    <ProtectedContext.Provider value={{ handleToggleNav, navIsHidded: isHidded }}>
      {children}
    </ProtectedContext.Provider>
  )
}

export const useProtected = (): ProtectedContextType => {
  const context = useContext(ProtectedContext)
  if (context === undefined) {
    throw new Error('useProtected must be used within an ProtectedProvider')
  }
  return context
}
