import * as Yup from 'yup'
import { useFormik } from 'formik'
import Button from '../../components/base/button'
import { RecoveryFormValues } from './types'
import AuthService from '../../services/auth.service'
import LogoutHeader from '../../components/logout-header'
import DefaultLoader from '../../components/base/loaders/default'
import { Input } from '../../components/base/input'
import { Icon } from '../../components/base/icon'

const initialValues: RecoveryFormValues = {
  username: ''
}

const validationSchema = Yup.object({
  username: Yup.string().email('Invalid email').required('Email is required')
})

type Props = {
  handleSubmit: (props: RecoveryFormValues) => void
  handleBack: () => void
}

const EmailForm: React.FC<Props> = ({ handleSubmit, handleBack }) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: RecoveryFormValues) => {
      try {
        await AuthService.login(values)
        handleSubmit(values)
      } catch (_) {}
    }
  })

  return (
    <div className='mb-6 flex flex-col items-center'>
      <LogoutHeader />

      <div className='mt-4 flex w-[454px] flex-col'>
        <button className='flex items-center' onClick={handleBack}>
          <Icon name='arrow-left' size='18px' />
          <span className='font-semibold text-neutral-8'>Back</span>
        </button>

        <div className='flex flex-col'>
          <span className='mt-3 text-xl font-medium text-neutral-8'>Enter you email</span>
          <span className='mt-2 text-xs font-regular text-neutral-32'>
            We’ll send you a verification code.
          </span>
        </div>

        {formik.isSubmitting ? (
          <DefaultLoader className='mt-8 w-full self-center' />
        ) : (
          <>
            <form onSubmit={formik.handleSubmit} className='mt-8 flex w-full flex-col gap-4'>
              <Input
                className='text-black'
                placeholder='Email'
                id='username'
                name='username'
                type='username'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
                maxLength={100}
                errorMessage={
                  formik.touched.username && formik.errors.username
                    ? formik.errors.username
                    : undefined
                }
              />

              <Button id='login' variant='primary' className='mt-4 rounded-sm py-4' type='submit'>
                Continue
              </Button>
            </form>
            <button
              id='forgot-password-button'
              className='font-no mt-11 w-fit text-2xs text-neutral-40 decoration-solid'
            >
              Need help? Please contact the support team.
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export default EmailForm
