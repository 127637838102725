import { useCallback, useEffect } from 'react'
import FocusTrap from 'focus-trap-react'

import { TModal } from './types'
import { AnimatePresence, motion } from 'framer-motion'

export const Modal: React.FC<TModal> = ({
  isOpen,
  children,
  ariaLabelledby,
  ariaDescribedby,
  withCloseButton,
  position = 'fixed',
  maxWidth = '400px',
  maxHeight = '90vh',
  minHeight = '200px',
  closeClickBg = true,
  onClose,
  className = '',
  modalBoxClassName = ''
}) => {
  const KEY_NAME_ESC = 'Escape'
  const KEY_EVENT_TYPE = 'keyup'

  const handleEscKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === KEY_NAME_ESC) onClose?.()
    },
    [onClose]
  )

  useEffect(() => {
    if (withCloseButton) document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false)

    return () => {
      if (withCloseButton) document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false)
    }
  }, [handleEscKey, withCloseButton])

  return (
    <AnimatePresence>
      {isOpen ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ velocity: 100 }}
        >
          <FocusTrap
            focusTrapOptions={{
              clickOutsideDeactivates: true
            }}
          >
            <div
              className={`inset-0 z-[999] flex ${position} items-center justify-center p-0 ${className}`}
            >
              <div
                onClick={closeClickBg ? onClose : undefined}
                className='absolute h-full w-full bg-[#000000bf]'
              />
              <div
                style={{ maxWidth, minHeight, maxHeight }}
                className={`bg-background-primary relative flex max-h-full w-full rounded-xl p-6 ${modalBoxClassName}`}
                tabIndex={0}
                role='dialog'
                aria-labelledby={ariaLabelledby}
                aria-describedby={ariaDescribedby}
              >
                {withCloseButton && (
                  <div className='absolute right-3.5 top-3.5 z-10'>
                    {/*   <button
              color='transparent'
              layout='circle'
              onClick={onClose}
              aria-label='Fechar modal'
              iconName='multiply'
            /> */}
                  </div>
                )}
                {children}
              </div>
            </div>
          </FocusTrap>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}
