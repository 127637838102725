import React, { useState, forwardRef } from 'react'
import { Modal } from '../../modals'
import { Props } from './types'
import { Icon } from '../../base/icon'
import { useFormik } from 'formik'
import Button from '../../base/button'
import { User } from '../../../types'
import CustomInputs from './custom-inputs'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import DefaultLoader from '../../base/loaders/default'

const FieldUpdater: React.FC<Props> = forwardRef(
  ({ steps, handleClose, isOpen, entity, handleUpdate }, ref: unknown) => {
    const [step, setStep] = useState(0)
    const currentStep = steps[step]

    const formik = useFormik({
      initialValues: currentStep?.option_data?.reduce(
        (acc, item) => {
          return { ...acc, [item.key]: entity[item.key as keyof typeof entity] || '' }
        },
        {} as typeof entity
      ),
      ...(steps[step].validationSchema && { validationSchema: steps[step].validationSchema }),
      onSubmit: async values => {
        if (currentStep.callback) {
          try {
            await currentStep.callback(values as User)
          } catch (err) {
            console.log('err', err)
            throw new Error()
          }
        }

        if (steps.length - 1 === step) {
          handleUpdate(values)
          handleClose()
        }

        setStep(previous => previous + 1)
      }
    })

    return (
      <Modal isOpen={isOpen} onClose={handleClose} ariaLabelledby={currentStep?.title}>
        {isOpen && (
          <div
            ref={ref as React.Ref<HTMLDivElement>}
            className='flex min-w-[480px] flex-col rounded-md bg-background-neutral-primary p-6'
          >
            <div className='mb-2 flex w-full items-center justify-between'>
              <span className='text-lg font-medium text-neutral-8'>{currentStep.title}</span>

              {!formik.isSubmitting && (
                <button onClick={handleClose} id='close_modal-button'>
                  <Icon name='times' size='20' />
                </button>
              )}
            </div>

            {formik.isSubmitting ? (
              <div className='flex w-full items-center justify-center py-8'>
                <DefaultLoader />
              </div>
            ) : (
              <>
                <AnimatePresence>
                  {currentStep?.option_data?.map(option => (
                    <motion.div
                      key={option?.label?.toString()}
                      className='align-start my-2 flex w-full flex-col'
                      initial={{ scale: 0.9, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                    >
                      <CustomInputs
                        name={option.key}
                        value={formik.values[option.key as keyof typeof formik.values]}
                        id={option.label.toLowerCase().toString()}
                        onChange={e =>
                          formik.handleChange({ target: { value: e, name: option.key } })
                        }
                        placeholder={option.label}
                        {...option}
                      />

                      <AnimatePresence>
                        {formik.errors[option.key as keyof typeof formik.values] && (
                          <motion.span
                            className='label-md mt-1 flex items-center gap-1 text-red-32'
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.9, opacity: 0 }}
                          >
                            <Icon
                              name='exclamation-circle-solid'
                              size='16px'
                              className='mt-0.5'
                              color='#DD1940'
                            />
                            {formik.errors[option.key as keyof typeof formik.values]}
                          </motion.span>
                        )}
                      </AnimatePresence>

                      {option.description && (
                        <span className='mt-2 text-3xs font-regular text-text-tertiary'>
                          {option.description}
                        </span>
                      )}
                    </motion.div>
                  ))}
                </AnimatePresence>
                <Button
                  id={`cotinue-${step}`}
                  variant='primary'
                  className='mt-8 rounded-sm'
                  onClick={formik.submitForm}
                >
                  {step === steps.length - 1 ? 'Update' : 'Continue'}
                </Button>
              </>
            )}
          </div>
        )}
      </Modal>
    )
  }
)

FieldUpdater.displayName = 'FieldUpdater'

export default FieldUpdater
