/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import React from 'react'
import { Props } from './types'
import { UserOnboardingParser } from '../../../../types'
import theme from '../../../../styles/figma-tokens.json'
import { motion } from 'framer-motion'
import { formatDate } from 'date-fns'
import { masks } from '../../../../components/base/input/common'
import { Icon } from '../../../../components/base/icon'
import { useNavigate } from 'react-router-dom'

export enum UserStatusParser {
  'active' = 'bg-background-success-secondary text-background-on-success-secondary',
  'pending' = 'bg-background-success-secondary text-background-on-success-secondary',
  'closed' = 'bg-background-success-secondary text-background-on-success-secondary',
  'suspended' = 'bg-background-success-secondary text-background-on-success-secondary',
  'inactive' = 'bg-background-critical-secondary text-background-on-critical-secondary'
}

const MembersTable: React.FC<Props> = ({ members }) => {
  const navigate = useNavigate()

  return (
    <motion.div
      className='mt-8 flex w-full flex-col'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className='sticky top-0 z-10 flex w-full bg-white py-2'>
        <span className='flex-1 px-3 text-2xs font-medium text-neutral-32'>Member</span>

        <div className='flex flex-[1.2]'>
          <span className='px-3 text-2xs font-medium text-neutral-32'>Status</span>
          <span className='px-8 text-2xs font-medium text-neutral-32'>Onboarding</span>
        </div>
        <span className='flex-1 px-3 text-2xs font-medium text-neutral-32'>Registration date</span>
      </div>

      {members.map(member => (
        <motion.div
          key={member.id}
          onClick={() => navigate(`${member.uuid}`)}
          className='relative flex w-full cursor-pointer items-center border-b-[1px] border-b-outline-default py-3'
        >
          <div className='flex flex-1 items-center px-3'>
            <div className='flex h-[42px] w-[42px] items-center justify-center rounded-sm bg-background-brown-secondary'>
              <span className='text-xs font-medium text-text-primary'>
                {member.name?.charAt(0).toUpperCase()}
              </span>
            </div>

            <div className='ml-3 flex flex-col'>
              <div className='flex items-center'>
                <span className='text-xs font-regular text-text-primary'>{member.name}</span>

                {member.role === 'admin' && (
                  <div className='ml-[10px] flex h-[24px] items-center rounded-full bg-background-neutral-secondary px-2'>
                    <span className='text-3xs font-regular text-text-primary'>
                      {member.role?.toUpperCase()}
                    </span>
                  </div>
                )}
              </div>

              <span className='text-2xs font-medium text-text-tertiary'>
                {masks['phone']((member.phone as string) || '')}
              </span>
            </div>
          </div>

          <div className='flex flex-[1.3] items-center'>
            {member.status && (
              <div className='flex min-w-[100px] justify-center'>
                <div
                  className={`${UserStatusParser[member.status]} flex h-[32px] w-fit items-center rounded-full px-3 py-1`}
                >
                  <span className='text-2xs font-regular'>
                    {member.status.charAt(0).toUpperCase()}
                    {member.status.substring(1, 99)}
                  </span>
                </div>
              </div>
            )}

            {member.onboarding && (
              <div className='px-3'>
                <span className='ml-1 text-xs font-regular text-text-primary'>
                  {UserOnboardingParser[member.onboarding]}
                </span>
              </div>
            )}
          </div>

          <div className='flex-1 px-3'>
            {member.createdAt && (
              <span className='text-xs font-regular text-text-primary'>
                {formatDate(new Date(member.createdAt), 'dd/MM/yyyy')}
              </span>
            )}
          </div>

          <div className='absolute right-0'>
            <Icon
              name='angle-right-alt'
              size='18px'
              color={theme['SC Tokens'].icon.color.primary.value}
            />
          </div>
        </motion.div>
      ))}
    </motion.div>
  )
}

export default MembersTable
