import { useState } from 'react'
import { masks } from './common'
import { TInput } from './types'
import { Icon } from '../icon'

const VARIANTS_WITH_TEXT: (keyof typeof masks)[] = [
  'text',
  'passport',
  'money',
  'negativeMoney',
  'negativeInteger'
]

export const Input: React.FC<TInput> = ({
  id,
  icon,
  type,
  value,
  max,
  min,
  prefix,
  layout = 'default',
  variant,
  className = '',
  isDisabled,
  placeholder,
  errorMessage,
  isTextArea,
  tipMessage,
  iconColor,
  onChange,
  onBlur,
  iconError,
  ...inputProps
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const colorBasedOnState = (isBorder = false) => {
    if (variant === 'search') return 'neutral-96'
    if (isDisabled) return 'content-disabled'
    if (errorMessage) return 'border-negative'

    return isBorder ? 'border-opaque' : 'content-primary'
  }

  const applyMask = () => masks[variant || 'text'](value || '')
  const removeMask = (val: string) =>
    variant === 'url' || variant === 'search' ? val : masks.removeMask(val)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!onChange) return null
    const event = max ? { ...e, target: { ...e.target, value: e.target.value.slice(0, max) } } : e

    const applyMask = () => {
      if (!variant || VARIANTS_WITH_TEXT.includes(variant)) return event

      return {
        ...event,
        target: { ...event.target, value: removeMask(event.target.value) }
      }
    }

    onChange(applyMask())
  }

  const layoutStyle = {
    small: 'min-h-[40px] px-4 rounded-sm',
    default: 'min-h-[56px] py-2 px-4 rounded-sm',
    rounded: 'rounded-full py-2	px-4'
  }

  const smallLabel = layout === 'small' || (layout === 'rounded' && !icon)

  const layoutTextStyle =
    !value || !placeholder
      ? 'body-md'
      : `label-xs ${smallLabel && !isTextArea ? 'top-[0.1rem]' : 'top-[0.3rem]'}`
  const textFocus = `group-focus-within:label-xs group-focus-within:text-outline-brand-primary group-focus-within:top-[0.3rem] ${smallLabel && !isTextArea ? 'group-focus-within:!top-[0.1rem]' : ''}`

  return (
    <div
      className={`input-content flex w-full flex-col ${className} ${variant === 'search' && 'bg-neutral-96'} rounded-sm`}
    >
      <label
        aria-invalid={!!errorMessage}
        className={`relative border-2 ${layoutStyle[layout]} border-${colorBasedOnState(
          true
        )} group flex w-full cursor-text ${isTextArea ? `h-40 items-start ${icon || prefix ? 'pt-4' : 'pt-6'}` : 'items-center'} aria-invalid:border-border-negative ${
          isDisabled && 'bg-background-disabled text-content-disabled border-none'
        } focus-within:border-outline-brand-primary`}
      >
        {prefix && prefix}

        {icon ? (
          <Icon name={icon} size='20px' {...(iconColor && { color: iconColor })} />
        ) : (
          <span
            className={`absolute max-w-full flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-text-tertiary transition-all duration-300 ${layoutTextStyle} text-${colorBasedOnState()} ${textFocus} ${prefix ? 'ml-7' : ''} ${
              errorMessage ? 'text-content-negative' : ''
            } ${isTextArea ? 'top-4' : ''}`}
          >
            {placeholder}
          </span>
        )}
        {isTextArea ? (
          <textarea
            id={id}
            placeholder={icon ? placeholder : undefined}
            value={applyMask()}
            maxLength={max}
            onChange={handleInputChange}
            disabled={isDisabled}
            aria-invalid={!!errorMessage}
            aria-describedby={errorMessage ? `${id}-error-message` : `${id}-tip-message`}
            aria-errormessage={`${id}-error-message`}
            className={`color-content-secondary body-md ${value && placeholder ? 'mt-auto' : ''} h-full w-full !outline-none ${prefix ? 'ml-2' : ''} ${icon ? 'ml-4' : ''}`}
            onBlur={onBlur}
            {...inputProps}
          />
        ) : (
          <input
            id={`${id}-input`}
            placeholder={icon ? placeholder : undefined}
            type={type === 'password' ? (showPassword ? 'text' : 'password') : type || 'text'}
            value={applyMask()}
            max={max}
            min={min}
            onChange={handleInputChange}
            disabled={isDisabled}
            aria-invalid={!!errorMessage}
            aria-describedby={errorMessage ? `${id}-error-message` : `${id}-tip-message`}
            aria-errormessage={`${id}-error-message`}
            className={`w-full text-black !outline-none ${variant === 'search' ? 'body-md ml-3 bg-neutral-96' : `body-md ${value && placeholder ? 'mt-auto' : ''} ${prefix ? 'ml-2' : ''} ${icon ? 'ml-4' : ''}`} `}
            onBlur={onBlur}
            {...inputProps}
          />
        )}
        {!isTextArea &&
          type === 'password' &&
          (showPassword ? (
            <button
              type='button'
              onClick={e => {
                e.preventDefault()
                setShowPassword(false)
              }}
              aria-label='Mostrar senha'
            >
              <Icon name='eye-slash' size='30' />
            </button>
          ) : (
            <button
              type='button'
              onClick={e => {
                e.preventDefault()
                setShowPassword(true)
              }}
              aria-label='Esconder senha'
            >
              <Icon name='eye' size='30' />
            </button>
          ))}
      </label>

      {errorMessage && (
        <span
          id={`${id}-error-message`}
          className='label-md text-content-negative mt-1 flex items-start gap-1'
        >
          {iconError ? (
            iconError
          ) : (
            <Icon name='exclamation-circle-solid' size='16px' className='mt-0.5' color='#DD1940' />
          )}
          {errorMessage}
        </span>
      )}
    </div>
  )
}
