import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react'
import AuthService from '../../services/auth.service'
import StorageService from '../../services/storage.service'
import { TwoFactoryFormValues } from '../../pages/login/types'
import { User } from '../../types'

interface AuthContextType {
  user: User | null
  login: (values: { username: string; password?: string }) => Promise<boolean>
  logout: () => void
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

interface AuthProviderProps {
  children: ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoadingLocalUser, setIsLoadingLocalUser] = useState(true)
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    const token = StorageService.getToken()

    if (token) {
      AuthService.getMyUser()
        .then(({ data }) => {
          setUser(data.user)
        })
        .catch(() => {
          StorageService.removeToken()
        })
        .finally(() => {
          setIsLoadingLocalUser(false)
        })
    } else {
      setIsLoadingLocalUser(false)
    }
  }, [])

  const login = async (values: TwoFactoryFormValues) => {
    const loginResponse = await AuthService.validateCode(values)
    StorageService.saveToken(loginResponse.headers.token)
    const userResponse = await AuthService.getMyUser()
    setUser(userResponse.data.user)
    return true
  }

  const logout = () => {
    StorageService.removeToken()
    setUser(null)
  }

  if (isLoadingLocalUser) {
    return <>Loading...</>
  }

  return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
