import { twMerge } from 'tailwind-merge'

export const cn = (classes: string[]) => twMerge([classes])

export const masks = {
  integer: (value: string) => value.replace(/\D/g, ''),
  search: (value: string) => value,
  creditCard: (value: string) => {
    if (value?.replace(/\D/g, '')?.length === 14) {
      return value
        ?.replace(/\D/g, '')
        ?.replace(/(\d{4})(\d)/, '$1 $2')
        ?.replace(/(\d{6})(\d)/, '$1 $2')
        ?.replace(/(\d{4})/, '$1')
    }

    if (value?.replace(/\D/g, '')?.length === 15) {
      return value
        ?.replace(/\D/g, '')
        ?.replace(/(\d{4})(\d)/, '$1 $2')
        ?.replace(/(\d{6})(\d)/, '$1 $2')
        ?.replace(/(\d{5})/, '$1')
    }

    return value
      ?.replace(/\D/g, '')
      ?.replace(/(\d{4})(\d)/, '$1 $2')
      ?.replace(/(\d{4})(\d)/, '$1 $2')
      ?.replace(/(\d{4})(\d)/, '$1 $2')
      ?.replace(/(\d{4})\d+?$/, '$1')
  },
  cnpj: (value: string) =>
    value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1'),
  passport: (value: string) => {
    if (value?.length <= 2) return value.toUpperCase().replace(/[^A-Z]{1,2}/g, '')

    const letters = value.slice(0, 2)
    const numbers = value.slice(2, 9)

    return `${letters}${numbers?.replace(/\D/g, '')}`.replace(/([^A-Z]{2})(\d{6})\b/, '$1 $2')
  },
  cpf: (value: string) =>
    value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1'),
  cep: (value: string) =>
    value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1'),
  removeMask: (value: string) => value.replace(/\./g, '').replace(/[^0-9]/g, ''),
  text: (value: string) => value,
  negativeInteger: (value: string) => {
    const isNegative = value?.[0] === '-'
    const content = isNegative ? value?.slice(1) : value

    const r = content?.replace(/\D/g, '') || ''
    return `${isNegative ? '-' : ''}${r ? parseInt(r) : ''}`
  },
  negativeMoney: (value: string) => {
    const isNegative = value?.[0] === '-'
    const content = isNegative ? value?.slice(1) : value

    let r = content?.replace(/\D/g, '')?.replace(/^0/, '') || ''
    if (r.length === 1) r = r.replace(/^(\d*).*/, '0,$1')
    if (r.length === 2) r = r.replace(/^(\d*)(\d\d).*/, '0,$2')
    else r = r.replace(/^(\d*)(\d\d).*/, '$1,$2')
    return `${isNegative ? '-' : ''}${r}`
  },
  date: (value: string) =>
    value
      .slice(0, 8)
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{4})/, '$1'),
  money: (value: string) => {
    let r = value?.replace(/\D/g, '')?.replace(/^0/, '') || ''
    if (r.length === 1) r = r.replace(/^(\d*).*/, '0,$1')
    if (r.length === 2) r = r.replace(/^(\d*)(\d\d).*/, '0,$2')
    else r = r.replace(/^(\d*)(\d\d).*/, '$1,$2')
    return r
  },
  url: (value: string) => {
    if (!value.startsWith('https://')) {
      value = 'https://' + value.replace(/https?:\/?\/?/g, '')
    }
    return value
  },
  phone: (value: string) => {
    if (!value) return ''

    // Remove caracteres não numéricos
    value = value.replace(/\D/g, '')

    // Verifica o código de discagem internacional (DD)
    if (value.startsWith('1')) {
      // Estados Unidos (+1)
      value = value.replace(/^1/, '')
      return value.length > 10
        ? `+1 (${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`
        : `+1 (${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6)}`
    } else if (value.startsWith('55')) {
      // Brasil (+55)
      value = value.replace(/^55/, '')
      if (value.length > 11) {
        return `+55 (${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`
      } else {
        return `+55 (${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6, 10)}`
      }
    } else {
      // Caso não tenha um DD conhecido
      return value.length > 10
        ? `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`
        : `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6, 10)}`
    }
  }
}

export type BucketParams = {
  spaceName: string
  accessKeyId: string
  secretAccessKey: string
  folder: string
}
