import { TwoFactoryFormValues } from '../pages/login/types'
import { AdminUser } from '../types'
import api from './api.service'

class AuthService {
  static login(body: { username: string; password?: string }) {
    return api.post<{ token: string }>('admin/start-verification', body)
  }

  static sendValidationCode(body: { email: string }) {
    return api.post<void>('/auth/send-code', body)
  }

  static validateCode(body: TwoFactoryFormValues) {
    return api.post<{ token: string }>('admin/login', body)
  }

  static sendRecoveryToken(body: { verificationToken: number; username: string }) {
    return api.post<string>('admin/generate-recovery-token', body)
  }

  static recoveryPassword(body: { recoveryToken: string; password: string; username: string }) {
    return api.put<string>('admin/password', body)
  }

  static getMyUser() {
    return api.get<AdminUser>('current-admin')
  }
}

export default AuthService
