import React from 'react'
import { Props } from './types'

const Button: React.FC<Props> = ({ children, variant, id, isLoading, ...props }) => {
  const VariantData: { [X in typeof variant]: string } = {
    primary: 'bg-brand-40 font-medium text-neutral-100 hover:bg-brand-32',
    outline: 'border-[1px] border-neutral-100 font-medium text-neutral-100',
    secondary: 'bg-neutral-96 hover:bg-neutral-88 text-neutral-8 font-medium ',
    primary_negative: 'bg-neutral-100 font-medium text-brand-40'
  }

  return (
    <button
      {...props}
      className={`${VariantData[variant]} ${props.className} px-4 py-2 `}
      id={`${id}-button`}
    >
      {isLoading ? <></> : children}
    </button>
  )
}

export default Button
