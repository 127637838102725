import React from 'react'
import { FieldProps } from '../../types'
import { Icon } from '../../../../../base/icon'
import theme from '../../../../../../styles/figma-tokens.json'

const CustomBox: React.FC<FieldProps> = ({ options, value, onChange }) => {
  return (
    <li className='flex w-full flex-col'>
      {options?.map(option => {
        const isChecked = value !== option.value
        return (
          <ul key={option.value.toString()} className='flex w-full  py-2'>
            <button
              onClick={() => onChange(option.value)}
              id={`${option.label.toLowerCase()}-button`}
              className={`flex w-full items-center justify-between border-[1px] px-4 py-3 ${isChecked ? `border-outline-default` : `border-outline-brand-primary bg-background-brand-secondary`} rounded-sm`}
            >
              <div className='flex items-center'>
                {option.icon && (
                  <Icon
                    name={option.icon}
                    size='22'
                    className='mr-1'
                    color={
                      isChecked
                        ? theme['SC Tokens'].outline.color.brand.primary.value
                        : theme['SC Tokens'].outline.color.default.value
                    }
                  />
                )}
                <div className='flex flex-col items-start'>
                  <span className='text-xs font-regular text-text-primary'>{option.label}</span>
                  {option.description && (
                    <span className='text-2xs font-regular text-text-secondary'>
                      {option.description}
                    </span>
                  )}
                </div>
              </div>
            </button>
          </ul>
        )
      })}
    </li>
  )
}

export default CustomBox
