import React, { useEffect, useState, useTransition } from 'react'
import { User } from '../../../../types'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import DefaultLoader from '../../../../components/base/loaders/default'
import { Icon } from '../../../../components/base/icon'
import UserService from '../../../../services/user.service'
import MemberNavigation from './navigation'
import * as TabComponents from './tabs'
import { TabItem } from '../../../../components/tab/types'

const Tabs: TabItem<keyof typeof TabComponents>[] = [
  {
    label: 'Member Info',
    icon: 'user-circle',
    key: 'info'
  },
  {
    label: 'Transactions',
    icon: 'upload',
    key: 'transaction'
  }
]

const UserDetail: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = Tabs.find(tab => tab.key === searchParams.get('t')) || Tabs[0]
  const [loading, startTransition] = useTransition()
  const TabComponent = TabComponents[tab.key]
  const [user, setUser] = useState<User>()
  const navigate = useNavigate()
  const { id } = useParams()

  if (!id) navigate(-1)

  useEffect(() => {
    startTransition(async () => {
      try {
        const user = await UserService.getById(id as string)
        if (!user) throw new Error('Usuário não encontrado')
        setUser(user)
      } catch (_) {
        navigate(-1)
      }
    })
  }, [id, navigate])

  return (
    <div className='flex h-full w-full justify-between'>
      <div className='flex h-full w-full flex-col items-start'>
        <button className='flex items-center' onClick={() => navigate(-1)}>
          <Icon name='arrow-left' size='18' />
          <span className='text-2xs font-medium text-black'>Back</span>
        </button>

        {!loading && user ? (
          <TabComponent user={user} />
        ) : (
          <div className='flex h-full w-full items-center justify-center'>
            <DefaultLoader />
          </div>
        )}
      </div>

      {!loading && user && (
        <MemberNavigation
          user={user}
          tabs={Tabs}
          current={tab}
          handleChange={e => setSearchParams({ t: e.key })}
        />
      )}
    </div>
  )
}

export default UserDetail
