import React, { useCallback, useEffect, useState } from 'react'
import Button from '../../../components/base/button'
import { Input } from '../../../components/base/input'
import theme from '../../../styles/figma-tokens.json'
import MembersTable from './table'
import { User } from '../../../types'
import UserService from '../../../services/user.service'
import DefaultLoader from '../../../components/base/loaders/default'
import { AnimatePresence } from 'framer-motion'
import { Pagination } from '../../../components/pagination'
import PageLimitSelector from '../../../components/pagination/limit-selector'
import { Icon } from '../../../components/base/icon'
import { IconNameType } from '../../../components/base/icon/Icon.data'
import { useSearchParams } from 'react-router-dom'
import { useDebounce } from '@uidotdev/usehooks'

const defaultTableParams = {
  filter: '',
  page: '0',
  limit: '50'
}

const HomeMembers = () => {
  const [members, setMembers] = useState({ isLoading: true, members: [] as User[], total: 0 })
  const [searchParams, setSearchParams] = useSearchParams(defaultTableParams)
  const filter = searchParams.get('filter') || defaultTableParams.filter
  const page = searchParams.get('page') || defaultTableParams.page
  const limit = searchParams.get('limit') || defaultTableParams.limit
  const filterDebounced = useDebounce(filter, 500)

  const handleRefreshMembers = useCallback(() => {
    setMembers(previous => ({ ...previous, isLoading: true }))

    UserService.list({
      limit: parseInt(limit),
      offset: parseInt(limit) * parseInt(page),
      search: filterDebounced
    }).then(members =>
      setMembers({ isLoading: false, members: members.users, total: members.total })
    )
  }, [page, limit, filterDebounced])

  useEffect(() => {
    handleRefreshMembers()
  }, [handleRefreshMembers])

  const tableButtons = [
    /*     {
      label: 'Filters',
      icon: 'sliders-v',
      onClick: () => {}
    },
    {
      label: 'Export',
      icon: 'upload',
      onClick: () => {}
    }, */
    {
      label: 'Refresh',
      icon: 'redo',
      onClick: handleRefreshMembers
    }
  ]

  return (
    <div className='flex h-full w-full flex-col'>
      <div className='flex items-center justify-between'>
        <span className='text-xl font-bold text-black'>Members</span>

        <div className='flex max-h-[40px] gap-4'>
          <Input
            id='search-member'
            placeholder='Search'
            icon='search'
            iconColor={theme.global.colorReference.neutral['40'].value}
            variant='search'
            value={filter}
            onChange={e =>
              setSearchParams({ page: defaultTableParams.page, limit, filter: e.target.value })
            }
            layout='small'
          />

          {tableButtons.map(button => (
            <Button
              className='flex items-center gap-1 rounded-sm'
              key={button.label.toLowerCase()}
              id={button.label.toLowerCase()}
              variant='secondary'
              onClick={button.onClick}
            >
              <Icon name={button.icon as IconNameType} size='18' />
              <span className='text-2xs font-bold text-black'>{button.label}</span>
            </Button>
          ))}
        </div>
      </div>

      <div className='h-full w-full overflow-auto'>
        <AnimatePresence>
          {members.isLoading ? (
            <div className='flex h-full items-center justify-center'>
              <DefaultLoader />
            </div>
          ) : (
            <MembersTable members={members.members} />
          )}
        </AnimatePresence>
      </div>

      <div className='mt-6 flex w-full justify-between'>
        <Pagination
          currentPage={parseInt(page) + 1}
          onPageChange={page => setSearchParams({ filter, page: (page - 1).toString(), limit })}
          totalCount={Math.round(members.total / parseInt(limit))}
        />

        <div className='flex items-center'>
          <span className='text-2xs font-regular text-text-secondary'>Rows per page:</span>
          <PageLimitSelector
            onChange={limit => setSearchParams({ filter, page, limit: limit.toString() })}
            value={parseInt(limit)}
          />
          <span className='text-2xs font-regular text-text-secondary'>
            1-{parseInt(limit) >= members.total ? members.total : limit} of {members.total}
          </span>
        </div>
      </div>
    </div>
  )
}

export default HomeMembers
