import React, { useState } from 'react'
import { Props } from './types'
import { Icon } from '../../base/icon'

const paginatorList = ['25', '50', '100']

const PageLimitSelector: React.FC<Props> = ({ onChange, value }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className='relative mx-2 flex h-[36px] w-[82px]'>
      {isOpen && (
        <div className='absolute bottom-[40px] flex w-full flex-col rounded-sm bg-white py-[2px] shadow-md'>
          {paginatorList.map(page => (
            <button
              onClick={() => {
                setIsOpen(false)
                onChange(parseInt(page))
              }}
              key={page}
              className='py-1 text-black hover:bg-neutral-92'
            >
              {page}
            </button>
          ))}
        </div>
      )}

      <div
        className='flex h-full w-full cursor-pointer items-center justify-between rounded-sm border-[1px] border-outline-default px-4 py-2'
        onClick={() => setIsOpen(p => !p)}
      >
        <span className='text-2xs font-regular text-black'>{value}</span>
        <Icon name='angle-down' size='14px' />
      </div>
    </div>
  )
}

export default PageLimitSelector
