import * as Yup from 'yup'
import { useFormik } from 'formik'
import Button from '../../components/base/button'
import React, { useState } from 'react'
import PinInput from '../../components/base/pin-input'
import { TwoFactoryFormValues } from './types'
import { useAuth } from '../../contexts/auth'
import Counter from '../../components/counter'
import LogoutHeader from '../../components/logout-header'
import AuthService from '../../services/auth.service'
import TryAgainModal from '../../components/modals/try-again'
import DefaultLoader from '../../components/base/loaders/default'
import { Icon } from '../../components/base/icon'

const validationSchema = Yup.object({
  verificationToken: Yup.string()
    .required('Verification code is required')
    .min(6, 'Enter the 6 digits')
})

type Props = {
  initialValues: {
    username: string
    password?: string
    verificationToken?: string
  }
  handleBack: () => void
  handleAuth?: (code: string) => void
}

const TwoFactoryForm: React.FC<Props> = ({ initialValues, handleBack, handleAuth }) => {
  const [expired, setExpired] = useState(false)
  const { login } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: TwoFactoryFormValues) => {
      try {
        if (handleAuth && values.verificationToken) {
          const code = await AuthService.sendRecoveryToken({
            username: initialValues.username,
            verificationToken: parseInt(values.verificationToken)
          })

          handleAuth(code.data)
          return
        }

        await login(values)
      } catch (_) {}
    }
  })

  const handleTryAgain = async () => {
    try {
      await AuthService.login({ username: initialValues.username })
      setExpired(false)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className='mb-6 flex flex-col items-center'>
      <LogoutHeader />

      <div className='mt-4 flex w-[454px] flex-col'>
        <Counter length={expired ? 0 : 600} handleExpire={() => setExpired(true)} />

        <button className='flex items-center' onClick={handleBack}>
          <Icon name='arrow-left' size='18px' />
          <span className='font-semibold text-neutral-8'>Back</span>
        </button>

        <div className='flex flex-col'>
          <span className='mt-3 text-xl font-medium text-neutral-8'>Verification code</span>
          <span className='mt-2 text-xs font-regular text-neutral-32'>
            Type the code sent to{' '}
            <u className='text-brand-40 no-underline'>{formik.values.username}</u>
          </span>
        </div>

        {formik.isSubmitting ? (
          <DefaultLoader className='mt-8 w-full self-center' />
        ) : (
          <form onSubmit={formik.handleSubmit} className='mt-8 flex w-full flex-col'>
            <PinInput
              id='two-factory'
              type={'number'}
              name='verificationToken'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.verificationToken}
              length={6}
              errorMessage={
                formik.touched.verificationToken && formik.errors.verificationToken
                  ? formik.errors.verificationToken
                  : undefined
              }
            />

            <button
              id='forgot-password-button'
              className='mt-8 w-fit text-2xs font-medium text-brand-40 underline decoration-solid'
            >
              RESEND CODE
            </button>

            <Button id='login' variant='primary' className='mt-8 rounded-sm py-4' type='submit'>
              Log in
            </Button>
          </form>
        )}
      </div>

      <TryAgainModal expired={expired} handleTryAgain={handleTryAgain} />
    </div>
  )
}

export default TwoFactoryForm
