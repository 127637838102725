import React, { useState } from 'react'
import Button from '../../base/button'
import { Props } from './types'
import { Modal } from '..'

const TryAgainModal: React.FC<Props> = ({ expired, handleTryAgain }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    setIsLoading(true)
    await handleTryAgain()
    setIsLoading(false)
  }

  return (
    <Modal isOpen={expired} ariaLabelledby='Reconfirm expire modal' minHeight='100px'>
      <div className='flex flex-col'>
        <span className='text-lg font-medium text-neutral-8'>Are you still there?</span>
        <span className='mt-2 text-xs font-regular text-neutral-24'>
          Your time expired but dont worry, you can try again.
        </span>

        <Button
          id='try-again'
          variant='primary'
          isLoading={isLoading}
          className='mt-10 w-fit self-end rounded-sm px-2 py-2'
          onClick={handleSubmit}
        >
          Try again
        </Button>
      </div>
    </Modal>
  )
}

export default TryAgainModal
