import React from 'react'
import { TabProps } from './types'

const Tab: React.FC<TabProps> = ({ tabs, current, handleChange }) => {
  const labelClass = {
    false: 'text-xs font-regular text-text-tertiary',
    true: 'text-xs font-medium text-text-primary'
  }

  return (
    <div className='flex w-full gap-4 border-b-[1px] border-b-neutral-88'>
      {tabs.map(tab => (
        <div key={tab.label.toLowerCase()} className='relative pb-3'>
          <button
            onClick={() => handleChange(tab)}
            id={`${tab.label.toLowerCase()}-button`}
            className={`${labelClass[(tab.label.toLowerCase() === current.label.toLowerCase()).toString() as keyof typeof labelClass]}`}
          >
            {tab.label}
          </button>

          {tab.label.toLowerCase() === current.label.toLowerCase() && (
            <div className='absolute bottom-0 h-[2px] w-full rounded-full bg-outline-brand-primary' />
          )}
        </div>
      ))}
    </div>
  )
}

export default Tab
