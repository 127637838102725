import React from 'react'
import { TabProps } from '../types'
import { TabItem } from '../../../../../../components/tab/types'
import * as TabComponents from './tabs'
import Tab from '../../../../../../components/tab'
import UserFields from './tabs/common'
import { useNavigate, useSearchParams } from 'react-router-dom'

const Tabs: TabItem<keyof typeof TabComponents>[] = [
  {
    label: 'About',
    key: 'about'
  },
  {
    label: 'Account',
    key: 'account'
  },
  {
    label: 'Address',
    key: 'address'
  }
]

const MemberInfo: React.FC<TabProps> = ({ user }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const memberTab = Tabs.find(tab => tab.key === searchParams.get('mt')) || Tabs[0]
  const tab = searchParams.get('t')

  const TabComponent = TabComponents[memberTab.key]
  const navigate = useNavigate()

  const handleUpdateUser = async (data: object) => {
    try {
      console.log('entity updated', data)
    } catch (err) {
      console.log(err)
      //do logic to show error when updates failed
    }
  }

  return (
    <div className='flex h-full w-full flex-col items-start'>
      <div className='mt-4 flex'>
        <button
          className='text-2xs font-regular text-text-primary underline'
          onClick={() => navigate(-1)}
        >
          Members{' '}
        </button>
        <span className='ml-1 text-2xs font-regular text-text-primary'>/ {user?.fullname}</span>
      </div>

      <span className='my-8 text-xl font-medium text-text-primary'>Member info</span>

      <Tab
        tabs={Tabs}
        current={memberTab}
        handleChange={e => setSearchParams({ ...(tab && { t: tab }), mt: e.key })}
      />

      {TabComponent && (
        <div className='h-full w-full py-8'>
          <TabComponent
            user={user}
            fields={UserFields[memberTab.key]}
            handleUpdate={handleUpdateUser}
          />
        </div>
      )}
    </div>
  )
}

export default MemberInfo
