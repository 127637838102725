import React, { useCallback, useEffect } from 'react'
import { embedDashboard } from '@superset-ui/embedded-sdk'
import { useParams } from 'react-router-dom'
import DashboardService from '../../../../services/dashboard.service'

const DashboardDetail = () => {
  const { id } = useParams()

  const getGuestToken = useCallback(async () => {
    return await DashboardService.getGuestToken(id || '')
  }, [id])

  useEffect(() => {
    embedDashboard({
      id: id || '', // given by the Superset embedding UI
      supersetDomain: process.env.REACT_APP_SUPERSET_DOMAIN || '',
      mountPoint: document.getElementById('superset-container') as HTMLElement, // any html element that can contain an iframe
      fetchGuestToken: () => getGuestToken(),
      dashboardUiConfig: {
        filters: {
          visible: false,
          expanded: false
        }
      }
    })
      .then(r => r)
      .catch(error => console.error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getGuestToken])

  return (
    <div
      id={'superset-container'}
      style={{ width: '100%', height: '100%', backgroundColor: '#F5F5F5' }}
    />
  )
}

export default DashboardDetail
