import React, { useState } from 'react'
import theme from '../../../../styles/tokens/global.json'
import { AnimatePresence, motion } from 'framer-motion'
import { useAuth } from '../../../../contexts/auth'
import { Icon } from '../../../base/icon'

const MenuDropdown = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const { logout } = useAuth()

  const MenuOptions = [
    {
      label: 'Logout',
      onClick: logout
    }
  ]

  return (
    <div className='relative flex flex-col'>
      <button id='user-menu-button' onClick={() => setMenuIsOpen(previous => !previous)}>
        <Icon name='angle-down' color={theme.colorReference.neutral[8].value} size='18px' />
      </button>

      <AnimatePresence>
        {menuIsOpen && (
          <motion.ul
            className={`absolute right-0 top-7 z-10 min-w-[122px] rounded-sm bg-neutral-100 px-2 shadow-sm`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {MenuOptions.map(option => (
              <li key={option.label} id={`${option.label.toLowerCase()}-list`}>
                <button
                  className='py-1 text-black'
                  id={`${option.label.toLowerCase()}-button`}
                  onClick={option.onClick}
                >
                  {option.label}
                </button>
              </li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  )
}

export default MenuDropdown
