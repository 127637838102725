import React from 'react'
import { IconNameType, icons } from './Icon.data'

type Props = {
  name: IconNameType
  color?: string
  size?: string
  className?: string
}

export const Icon: React.FC<Props> = ({ name, color, size, className }) => {
  return icons[name] ? (
    <div>
      <svg width={size} height={size} className={className} viewBox='0 0 1024 1024'>
        <path d={icons[name]} fill={color} />
      </svg>
    </div>
  ) : (
    <></>
  )
}
