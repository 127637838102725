import React, { useMemo } from 'react'
import { FieldProps } from './types'
import { Input } from '../../../base/input'
import * as Inputs from './inputs'
import { OptionData } from '../../../../pages/home/members/detail/tabs/member-info/tabs/types'

const CustomInputs: React.FC<FieldProps> = ({ type, ...props }) => {
  const Component = useMemo(() => {
    const availableComponents: { [x in OptionData['type']]: React.FC<FieldProps> } = {
      ...Inputs,
      input: props => (
        <Input
          {...props}
          value={props.value as string}
          onChange={e => props.onChange(e.target.value)}
        />
      )
    }

    return availableComponents[type]
  }, [type])
  return <Component {...props} type={type} />
}

export default CustomInputs
