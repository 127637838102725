import React from 'react'

const LogoutHeader: React.FC = () => {
  return (
    <div className='w-screen items-start border-b-[1px] border-b-neutral-72 px-8 py-4'>
      <img
        alt={'Savings collaborative full logo'}
        src='/icons/logos/full.svg'
        className='h-10 w-44 self-start'
      />
    </div>
  )
}

export default LogoutHeader
