import { router } from './routes'
import './styles/global.css'
import './styles/index.css'
import { RouterProvider } from 'react-router-dom'
import { AuthProvider } from './contexts/auth'

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  )
}

export default App
