/* import LoginForm from './login-form' */
import { useState } from 'react'
import TwoFactoryForm from '../login/two-factory-form'
import { RecoveryFormValues } from './types'
import EmailForm from './email-form'
import { useNavigate } from 'react-router-dom'
import ResetPassword from './reset-password'

const ForgotPasswordPage = () => {
  const [loginData, setLoginData] = useState<RecoveryFormValues>()
  const [twoFactoryCode, setTwoFactoryCode] = useState<string>()
  const navigate = useNavigate()

  return (
    <div className='flex h-screen w-screen justify-center bg-neutral-100'>
      {twoFactoryCode && loginData ? (
        <ResetPassword
          handleBack={() => setTwoFactoryCode(undefined)}
          initialValues={{ ...loginData, code: twoFactoryCode }}
        />
      ) : (
        <>
          {!loginData ? (
            <EmailForm handleSubmit={setLoginData} handleBack={() => navigate(-1)} />
          ) : (
            <TwoFactoryForm
              initialValues={loginData}
              handleBack={() => setLoginData(undefined)}
              handleAuth={setTwoFactoryCode}
            />
          )}
        </>
      )}
    </div>
  )
}

export default ForgotPasswordPage
