import React from 'react'
import { Props } from './types'
import Button from '../../../../components/base/button'
import { Icon } from '../../../../components/base/icon'
import theme from '../../../../styles/figma-tokens.json'
import { AnimatePresence, motion } from 'framer-motion'

const DashboardCard: React.FC<Props> = ({ dashboard, handleLike, handleOpen }) => {
  return (
    <div
      key={dashboard.id}
      className='flex h-80 w-full flex-col rounded-sm border-[1px] border-outline-default'
    >
      <img src={dashboard.image} className='min-h-32 w-full' alt={`Image of ${dashboard.name}`} />

      <div className='flex h-full flex-col justify-between p-4'>
        <div className='flex flex-col'>
          <span className='text-md font-medium text-text-primary'>{dashboard.name}</span>
          <span className='mt-2 text-2xs font-regular text-text-tertiary'>
            {dashboard.description}
          </span>
        </div>

        <div className='flex w-full gap-2'>
          <Button
            variant='secondary'
            className='w-full rounded-sm'
            id={`open-${dashboard.id}`}
            onClick={handleOpen}
          >
            Open
          </Button>

          <Button
            variant='secondary'
            onClick={handleLike}
            className='rounded-sm'
            id={`open-${dashboard.id}`}
          >
            <AnimatePresence>
              {dashboard.isLiked ? (
                <motion.div initial={{ scale: 0.9 }} animate={{ scale: 1.1 }} exit={{ scale: 0.9 }}>
                  <Icon
                    name={'heart-solid'}
                    color={theme.global.colorReference.brand[40].value}
                    size='18px'
                  />
                </motion.div>
              ) : (
                <Icon
                  name={'heart'}
                  color={theme['SC Tokens'].icon.color.primary.value}
                  size='18px'
                />
              )}
            </AnimatePresence>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DashboardCard
