import React, { useEffect } from 'react'
import theme from '../../../styles/tokens/global.json'
import NavItems, { NavItem } from '../../../contexts/ProtectedContext/common'
import { useProtected } from '../../../contexts/ProtectedContext'
import { motion, useAnimationControls } from 'framer-motion'
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '../../base/icon'

const Navbar = () => {
  const controls = useAnimationControls()
  const { navIsHidded } = useProtected()
  const path = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    controls.start({ width: navIsHidded ? '44px' : '200px' })
  }, [navIsHidded, controls])


  const isCurrentPath = (item: NavItem) => {
    if(path.pathname === '/' && item.route === '/dashboards')
      return  true

    if(item.route && path.pathname.includes(item.route))
      return true

    return false
    }


  return (
    <motion.div className='h-full bg-neutral-100 px-2 py-4'>
      {NavItems.map((tab, tabIn) => (
        <motion.li key={tabIn} className='w-[50px] list-none overflow-hidden' animate={controls}>
          {tab
            .filter(item => !item.hidden)
            .map((item, itemIn) => (
            <ul
              key={`${item.label.toLowerCase()}-${itemIn}`}
              className={` ${isCurrentPath(item) ? 'bg-brand-96' : 'transparent'} rounded-sm`}
            >
              <button
                id={`${item.label.toLowerCase()}-button`}
                className='flex w-full items-center gap-3 p-3'
                onClick={() => {
                  navigate(item.route || '')
                }}
              >
                <Icon
                  size='20px'
                  name={item.icon}
                  color={
                    isCurrentPath(item)
                      ? theme.colorReference.brand[48].value
                      : theme.colorReference.neutral[40].value
                  }
                />

                <span
                  className={`${isCurrentPath(item) ? 'text-brand-48' : 'text-text-tertiary'}`}
                >
                  {item.label}
                </span>
              </button>
            </ul>
          ))}

          {
            // it is not the last one
            NavItems.length - 1 !== tabIn &&
            // it has at least one visible item
            tab.filter(item => !item.hidden).length > 0 &&
            // next list has at least one visible item
            NavItems[tabIn+1].filter(item => !item.hidden).length > 0 &&
            // show divider
            <hr className='mt-4 pt-4' />}
        </motion.li>
      ))}
    </motion.div>
  )
}

export default Navbar
