import React from 'react'
import { useAuth } from '../../../contexts/auth'
import MenuDropdown from './dropdown'
import theme from '../../../styles/figma-tokens.json'
import { useProtected } from '../../../contexts/ProtectedContext'
import { Icon } from '../../base/icon'

const Menu = () => {
  const { user } = useAuth()
  const { handleToggleNav } = useProtected()

  return (
    <div className='flex w-screen justify-between bg-neutral-100 px-8 py-4'>
      <div className='flex items-center'>
        <button onClick={handleToggleNav}>
          <Icon name='bars' size='18px' color={theme['SC Tokens'].icon.color.tertiary.value} />
        </button>

        <img
          alt={'Savings collaborative full logo'}
          src='/icons/logos/full.svg'
          className='ml-3 h-8 w-36 self-start'
        />
      </div>

      <div className='flex items-center gap-4'>
        <div className='flex h-6 w-6 items-center justify-center rounded-xs bg-background-brand-secondary text-background-on-brand-secondary'>
          <span className='text-2xs font-medium'>{user?.name.charAt(0).toUpperCase()}</span>
        </div>
        <span className='text-neutral-8'>{user?.name}</span>

        <MenuDropdown />
      </div>
    </div>
  )
}

export default Menu
